import React, { useEffect, useContext, useState } from 'react';
import web3 from './connection/web3';
import Web3Context from './store/web3-context';
import TokenContext from './store/token-context';
import UserContext from './store/user-context';
import CryptoToken from './contracts/Token.json';
import UserInfo from './contracts/TokenSale.json';
import CustomTokenAbi from './TokenAbi.json';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { appSettings } from './helpers/settings';
import './scss/app.scss';
import 'aos/dist/aos.css';
import 'react-popper-tooltip/dist/styles.css';
import Header from './components/general/Header';
import ViewOnlyAlert from './components/general/ViewOnlyAlert';
import Home from './components/Home';
import MintPeriod from './components/MintPeriod';
import Activity from './components/Activity';
import Admin from './components/Admin';
import Users from './components/Users';
import UserSingle from './components/UserSingle';
import UserDetails from './components/UserDetails';
import NotFound from './components/NotFound';
import Footer from './components/general/Footer';
import NetworkAlert from './components/general/NetworkAlert';
import Web3 from 'web3';

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

function App() {
    const web3Ctx = useContext(Web3Context);
    const tokenCtx = useContext(TokenContext);
    const userCtx = useContext(UserContext);
    const [networkError, setNetworkError] = useState(false);
    const [networkId, setNetworkId] = useState(4);
    const [web3Provider, setWeb3Provider] = useState(
        window.ethereum ? new Web3(window.ethereum) : new Web3(appSettings.rpcUrl)
    );

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(new Web3(window.ethereum));
                setNetworkId(networkId);
            }
        }
        getNetworkId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tokenCtx.customTokenContract) {
            tokenCtx.loadTokenData(tokenCtx.customTokenContract);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenCtx.customTokenContract]);

    /*** =============================================== */
    //      TOGGLE WEB3 PROVIDER
    /*** =============================================== */
    useEffect(() => {
        if (window.ethereum && networkId === appSettings.networkId) {
            setWeb3Provider(new Web3(window.ethereum));
        } else {
            setWeb3Provider(new Web3(appSettings.rpcUrl));
        }
    }, [networkId]);

    useEffect(() => {
        if (!web3) {
            return;
        }
        const loadBlockChainData = async () => {
            // Load account
            const account = await web3Ctx.loadAccount(web3Provider);

            // Load Network ID
            const networkId = await web3Ctx.loadNetworkId(web3Provider);

            // Load Contracts
            const tokenDeployedNetwork = CryptoToken.networks[networkId];
            const userDeployedNetwork = UserInfo.networks[networkId];
            const tokenContract = tokenCtx.loadContract(web3Provider, CryptoToken, tokenDeployedNetwork);
            const userContract = userCtx.loadContract(web3Provider, UserInfo, userDeployedNetwork);
            const customTokenContract = account
                ? tokenCtx.loadCustomTokenContract(web3Provider, CustomTokenAbi, appSettings.tokenAddress, account)
                : '';

            // If Token contract Loaded
            if (tokenContract) {
                tokenCtx.loadTotalSupply(tokenContract);
                tokenCtx.loadActivePeriod(tokenContract);
                tokenCtx.loadPeriods(tokenContract);
                userCtx.getAppOwner(tokenContract);
                tokenCtx.loadTokenPrice(tokenContract);
                // tokenCtx.loadMinimumTokens(tokenContract);
                account && tokenCtx.loadPurchasedTokens(tokenContract, account);
                if (customTokenContract) {
                    account && userCtx.loadUserBalance(customTokenContract, account);
                }
                if (tokenCtx.activePeriod && tokenCtx.activePeriod.status) {
                    tokenCtx.loadMinimumTokens(tokenContract);
                }
            } else {
                setNetworkError(true);
            }

            // If User contract Loaded
            if (userContract) {
                userCtx.setUserIsLoading(false);
                tokenCtx.loadActivity(userContract);
                if (customTokenContract) {
                    account && userCtx.getUserInformation(userContract, customTokenContract, account);
                    userCtx.getUsersList(userContract, customTokenContract, tokenContract);
                }
                userCtx.loadWhiteList(userContract);
                tokenCtx.loadPendingTokens(userContract);
            } else {
                userCtx.setUserIsLoading(true);
            }

            tokenCtx.setTokenIsLoading(false);
            userCtx.setUserIsLoading(false);
            if (window.ethereum && networkId === appSettings.networkId) {
                // Metamask Event Subscription - Network changed
                window.ethereum.on('chainChanged', (chainId) => {
                    window.location.reload();
                });

                // Metamask Event Subscription - Account changed
                window.ethereum.on('accountsChanged', (accounts) => {
                    window.location.reload();
                });
            }
        };

        loadBlockChainData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BrowserRouter>
            {!tokenCtx.tokenIsLoading && (
                <>{(window.ethereum && networkId === appSettings.networkId) || <ViewOnlyAlert />}</>
            )}
            {networkError && <NetworkAlert />}
            <div className='app'>
                <Header />
                <div id='main' className='layout-horizontal z-index-20 position-relative'>
                    <div className='content-wrapper container'>
                        <Routes>
                            <Route path='/' exact element={<Home />} />
                            <Route
                                path='/mint'
                                element={Boolean(userCtx.appOwner === web3Ctx.account) ? <MintPeriod /> : <NotFound />}
                            />
                            <Route path='/my-info' element={<UserDetails />} />
                            <Route path='/activity' element={<Activity />} />
                            <Route
                                path='/admin'
                                element={Boolean(userCtx.appOwner === web3Ctx.account) ? <Admin /> : <NotFound />}
                            />
                            <Route path='/users' element={<Users />} />
                            <Route path='/users/:address' element={<UserSingle />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </div>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
