import React, { useEffect, useState, useContext } from 'react';
import AOS from 'aos';
import { useToasts } from 'react-toast-notifications';
import TokenContext from '../../store/token-context';
import UserContext from '../../store/user-context';
import Web3Context from '../../store/web3-context';
import web3 from '../../connection/web3';
import MetaMaskLoader from '../general/MetaMaskLoader';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';

function VestingTokens() {
    const tokenCtx = useContext(TokenContext);
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const [metaMaskOpened, setMetaMaskOpened] = useState(false);
    const [initialAmount, setInitialAmount] = useState(0);
    const { addToast } = useToasts();
    const navigate = useNavigate();

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    /*** =============================================== */
    //      ADD TOKENS TO THE APP
    /*** =============================================== */
    let amount = web3.utils.toHex(web3.utils.toWei(initialAmount.toString()));
    function addVestingTokensHandler(e) {
        e.preventDefault();
        tokenCtx.customTokenContract.methods
            .approve(tokenCtx.contract.options.address, amount)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                setMetaMaskOpened(true);
            })
            .on('transactionHash', (hash) => {
                setMetaMaskOpened(true);
            })
            .on('receipt', (receipt) => {
                setMetaMaskOpened(false);
                tokenCtx.contract.methods
                    .initialAmount(amount)
                    .send({ from: web3Ctx.account })
                    .once('sending', () => {
                        setMetaMaskOpened(true);
                    })
                    .on('transactionHash', (hash) => {
                        setMetaMaskOpened(true);
                    })
                    .on('receipt', () => {
                        setMetaMaskOpened(false);
                        tokenCtx.loadTotalSupply(tokenCtx.contract);
                        tokenCtx.loadActivePeriod(tokenCtx.contract);
                        tokenCtx.loadPeriods(tokenCtx.contract);
                        userCtx.getAppOwner(tokenCtx.contract);
                        tokenCtx.loadTokenPrice(tokenCtx.contract);
                        tokenCtx.loadPurchasedTokens(tokenCtx.contract, web3Ctx.account);
                        userCtx.loadUserBalance(tokenCtx.customTokenContract, web3Ctx.account);
                        userCtx.getUserInformation(userCtx.contract, tokenCtx.customTokenContract, web3Ctx.account);
                        userCtx.getUsersList(userCtx.contract, tokenCtx.customTokenContract, tokenCtx.contract);
                        setTimeout(() => navigate('/mint'), 1000);
                        addToast('Great! you have added vesting tokens to the app', {
                            appearance: 'success',
                        });
                    })
                    .on('error', (error) => {
                        addToast('Something went wrong when pushing to the blockchain', {
                            appearance: 'error',
                        });
                        setMetaMaskOpened(false);
                    });
            })
            .on('error', (error) => {
                addToast('Something went wrong when pushing to the blockchain', {
                    appearance: 'error',
                });
                setMetaMaskOpened(false);
            });
    }

    return (
        <>
            {metaMaskOpened ? <MetaMaskLoader /> : null}
            <div className='card shadow-lg' data-aos='fade-up' data-aos-delay='300'>
                <div className='card-body p-lg-5'>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <h2 className='h4'>Add Token to sell</h2>
                            <p className='text-muted text-sm mb-4'>
                                choose a specific amount from your token balance to start selling it.
                            </p>
                        </div>
                        <div className='col-lg-6 d-none d-lg-block text-end'>
                            <p className='mb-0'>
                                Your <span className='text-primary fw-bold mx-2'>{tokenCtx.tokenData.symbol}</span>{' '}
                                Balance
                            </p>
                            {userCtx.userInformation && (
                                <h4 className='text-primary h2'>
                                    {parseFloat(Web3.utils.fromWei(userCtx.userInformation.balance, 'ether')) -
                                        parseFloat(initialAmount)}
                                </h4>
                            )}
                        </div>
                    </div>

                    <form onSubmit={addVestingTokensHandler}>
                        <label htmlFor='tokenAmount' className='form-label'>
                            Token Amount
                        </label>
                        <input
                            className='form-control mb-3'
                            type='number'
                            step='1'
                            min='0'
                            max={`${
                                userCtx.userInformation && Web3.utils.fromWei(userCtx.userInformation.balance, 'ether')
                            }`}
                            id='tokenAmount'
                            placeholder='Add token balance'
                            onChange={(e) => setInitialAmount(e.target.value > 0 ? e.target.value : 0)}
                        />
                        <button className='btn btn-primary w-100' type='submit'>
                            Add Tokens
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default VestingTokens;
