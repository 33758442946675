import { Jazzicon } from '@ukstv/jazzicon-react';
import AOS from 'aos';
import React, { useContext, useEffect, useState } from 'react';
import { MdVerified } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { appSettings } from '../helpers/settings';
import { toEther } from '../helpers/utils';
import TokenContext from '../store/token-context';
import UserContext from '../store/user-context';
import Web3Context from '../store/web3-context';
import FetchingDataLoader from './general/FetchingDataLoader';
import UserActivityTable from './tables/UserActivityTable';

function UserSingle() {
    const { address } = useParams();
    const userCtx = useContext(UserContext);
    const tokenCtx = useContext(TokenContext);
    const web3Ctx = useContext(Web3Context);
    const [usersList, setUsersList] = useState(null);
    const [purchasedToken, setPurchasedToken] = useState(0);
    const [pendingTokens, setPendingTokens] = useState(0);
    const [totalShare, setTotalShare] = useState(0);
    const { addToast } = useToasts();

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    /*** =============================================== */
    //      GET USER INFO
    /*** =============================================== */
    useEffect(() => {
        if (userCtx.usersList) {
            const userInfo = userCtx.usersList.filter((user) => user.account === address)[0];
            setUsersList(userInfo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCtx.usersList, userCtx.whiteList]);

    /*** =============================================== */
    //      GET USER BALANCE
    /*** =============================================== */
    useEffect(() => {
        if (userCtx.usersList) {
            setPurchasedToken(userCtx.usersList.filter((user) => user.account === address)[0].balance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCtx.usersList]);

    /*** =============================================== */
    //      CALCULATE USER PERCENTAGE OF THE TOKEN
    /*** =============================================== */
    useEffect(() => {
        if (tokenCtx.totalSupply && userCtx.userBalance && userCtx.usersList) {
            const totalAmounts = toEther(tokenCtx.totalSupply);
            const userTokens = parseFloat(userCtx.usersList.filter((user) => user.account === address)[0].balance);
            setTotalShare(((userTokens / totalAmounts) * 100).toFixed(3));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenCtx.totalSupply, userCtx.usersList, userCtx.userBalance]);

    /*** =============================================== */
    //      GET PENDING TOKENS
    /*** =============================================== */
    useEffect(() => {
        if (tokenCtx.pendingTokens) {
            setPendingTokens(
                tokenCtx.pendingTokens.filter((token) => token.account === web3Ctx.account).map((token) => token.amount)
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenCtx.pendingTokens]);

    /*** =============================================== */
    //      COPY USER ADDRESS
    /*** =============================================== */
    function copyText(e) {
        e.target.classList.add('bounced');
        setTimeout(() => {
            e.target.classList.remove('bounced');
        }, 500);
        navigator.clipboard.writeText(e.target.textContent);
        addToast(`Copied`, { appearance: 'success' });
    }

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${appSettings.brandName} | ${usersList ? usersList.fullName : ''}`;
    }, [usersList]);

    if (userCtx.userIsLoading && !userCtx.usersList) {
        return <FetchingDataLoader />;
    }

    return (
        <>
            <div className='row my-5 pt-4'>
                <div className='col-lg-9 mx-auto'>
                    {usersList && (
                        <div className='text-center'>
                            <div className='d-flex align-items-center justify-content-center text-start'>
                                <div
                                    className='flex-shrink-0'
                                    style={{ width: '50px', height: '50px' }}
                                >
                                    <Jazzicon address={usersList && usersList.account} />
                                </div>
                                <div className='ms-3'>
                                    {usersList.role && (
                                        <span
                                            className='badge bg-primary ms-auto'
                                        >
                                            {usersList.role}
                                        </span>
                                    )}
                                    <h1
                                        className='mb-0 d-flex align-items-center'
                                    >
                                        {usersList.fullName}
                                        {userCtx.whiteList &&
                                            userCtx.whiteList.some((user) => user.address === usersList.account) && (
                                                <MdVerified
                                                    className='text-success ms-2'
                                                    size='1.2rem'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='left'
                                                    title='WhiteListed'
                                                />
                                            )}
                                        {usersList.account === userCtx.appOwner && (
                                            <MdVerified
                                                className='text-info ms-2'
                                                size='1.2rem'
                                                data-bs-toggle='tooltip'
                                                data-bs-placement='left'
                                                title='WhiteListed'
                                            />
                                        )}
                                    </h1>
                                    {usersList.email && (
                                        <p className='mb-0'>
                                            {usersList.email}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div
                                className='mt-3 rounded-lg bg-gray-800 py-2 px-4 d-inline-block mx-auto copyable border-0 shadow-0'
                                onClick={copyText}
                            >
                                {usersList.account}
                            </div>

                            {usersList.about && (
                                <div className='row'>
                                    <div className='col-lg-9 mx-auto'>
                                        <p className='mt-3'>{usersList.about}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <div className='row g-4 mt-4'>
                        {usersList && usersList.account !== userCtx.appOwner && (
                            <>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 card-hover-animated'>
                                        <div className='card-body'>
                                            <div className='d-flex'>
                                                <div className='stats-icon solid-turquoise'>
                                                    {/* <FaBitcoin size='1.4rem' /> */}
                                            <img src='/logo-only-black.svg' alt='bymci-token-logo' width={32} />
                                                </div>
                                                <div className='ms-3'>
                                                    <h6 className='text-muted mb-0'>Purchased tokens</h6>
                                                    <h6 className='h4 fw-normal mb-0'>
                                                        {purchasedToken && purchasedToken > 0 ? purchasedToken : 0}{' '}
                                                        <span className='text-sm fw-bold text-muted'>
                                                            {tokenCtx.tokenData.symbol}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 card-hover-animated'>
                                        <div className='card-body'>
                                            <div className='d-flex'>
                                                <div className='stats-icon solid-cyan'>
                                                    {/* <FaBitcoin size='1.4rem' /> */}
                                            <img src='/logo-only-black.svg' alt='bymci-token-logo' width={32} />
                                                </div>
                                                <div className='ms-3'>
                                                    <h6 className='text-muted mb-0'>pending tokens</h6>
                                                    <h6 className='h4 fw-normal mb-0'>
                                                        {pendingTokens > 0 ? parseFloat(pendingTokens) / 10 ** 18 : 0}{' '}
                                                        <span className='text-sm fw-bold text-muted'>
                                                            {tokenCtx.tokenData.symbol}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 card-hover-animated'>
                                        <div className='card-body'>
                                            <div className='d-flex'>
                                                <div className='stats-icon solid-orange'>
                                                    {/* <FaBitcoin size='1.4rem' /> */}
                                            <img src='/logo-only-black.svg' alt='bymci-token-logo' width={32} />
                                                </div>
                                                <div className='ms-3'>
                                                    <h6 className='text-muted mb-0'>Total share</h6>
                                                    <h6 className='h4 fw-normal mb-0'>
                                                        {totalShare > 0 ? totalShare + '%' : '0%'}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className='col-lg-12'>
                            <UserActivityTable />
                            <div className='text-center'>
                                <Link to='/users' className='btn btn-primary py-2'>
                                    Back to Users List
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserSingle;
