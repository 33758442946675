import React, { useEffect, useContext } from 'react';
import TokenContext from '../store/token-context';
import { appSettings } from '../helpers/settings';
import AOS from 'aos';

import FetchingDataLoader from './general/FetchingDataLoader.js';
import ActivityTable from './tables/ActivityTable.js';
import PeriodsTable from './tables/PeriodsTable';

function Activity() {
    const tokenCtx = useContext(TokenContext);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${appSettings.brandName} | Activity`;
    }, []);

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    if (tokenCtx.tokenIsLoading) {
        return <FetchingDataLoader />;
    }

    return (
        <>
            <div className='row my-5 pt-4'>
                <div className='col-lg-9 mx-auto text-center'>
                    <h1 data-aos='fade-up'>Transactions Activity</h1>
                    <p className='text-muted lead' data-aos='fade-up' data-aos-delay='100'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-12'>
                    <ActivityTable />
                    <PeriodsTable />
                </div>
            </div>
        </>
    );
}

export default Activity;
