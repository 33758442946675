import React, { useEffect, useState, useContext } from 'react';
import AOS from 'aos';
import { useToasts } from 'react-toast-notifications';
import TokenContext from '../../store/token-context';
import UserContext from '../../store/user-context';
import Web3Context from '../../store/web3-context';
import web3 from '../../connection/web3';
import MetaMaskLoader from '../general/MetaMaskLoader';
import { useNavigate } from 'react-router-dom';

function WithdrawTokens() {
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    const tokenCtx = useContext(TokenContext);
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const [metaMaskOpened, setMetaMaskOpened] = useState(false);
    const [totalAvailable, setTotalAvailable] = useState(0);
    const { addToast } = useToasts();
    const navigate = useNavigate();

    useEffect(() => {
        if (userCtx.usersList && tokenCtx.totalSupply) {
            const totalSold = userCtx.usersList
                .filter((user) => user.account !== userCtx.appOwner)
                .map((period) => period.balance)
                .reduce((a, b) => a + b, 0);
            const totalSupply = parseFloat(tokenCtx.totalSupply) / 10 ** 18;
            setTotalAvailable(totalSupply - totalSold);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCtx.usersList, tokenCtx.totalSupply]);

    let amount = web3.utils.toHex(web3.utils.toWei(tokenCtx.totalSupply.toString()));

    function removeVestingTokens(e) {
        e.preventDefault();
        tokenCtx.customTokenContract.methods
            .approve(web3Ctx.account, amount)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                setMetaMaskOpened(true);
            })
            .on('transactionHash', (hash) => {
                setMetaMaskOpened(true);
            })
            .on('receipt', (receipt) => {
                setMetaMaskOpened(false);
                tokenCtx.contract.methods
                    .withdraw()
                    .send({ from: web3Ctx.account })
                    .once('sending', () => {
                        setMetaMaskOpened(true);
                    })
                    .on('transactionHash', (hash) => {
                        setMetaMaskOpened(true);
                    })
                    .on('receipt', () => {
                        setMetaMaskOpened(false);
                        tokenCtx.loadTotalSupply(tokenCtx.contract);
                        tokenCtx.loadActivePeriod(tokenCtx.contract);
                        tokenCtx.loadPeriods(tokenCtx.contract);
                        userCtx.getAppOwner(tokenCtx.contract);
                        tokenCtx.loadTokenPrice(tokenCtx.contract);
                        tokenCtx.loadPurchasedTokens(tokenCtx.contract, web3Ctx.account);
                        userCtx.loadUserBalance(tokenCtx.customTokenContract, web3Ctx.account);
                        userCtx.getUserInformation(userCtx.contract, tokenCtx.customTokenContract, web3Ctx.account);
                        userCtx.getUsersList(userCtx.contract, tokenCtx.customTokenContract, tokenCtx.contract);
                        addToast(`Great! you have widthrawn your token successfully`, {
                            appearance: 'success',
                        });
                        setTimeout(() => navigate('/'), 1000);
                    });
            })
            .on('error', (error) => {
                addToast('Something went wrong when pushing to the blockchain', {
                    appearance: 'error',
                });
                setMetaMaskOpened(false);
            });
    }

    return (
        <>
            {metaMaskOpened ? <MetaMaskLoader /> : null}
            <div className='card shadow-lg' data-aos='fade-up' data-aos-delay='300'>
                <div className='card-body p-lg-5'>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <h2 className='h4'>Withdraw Tokens to Your Wallet</h2>
                            <p className='text-muted text-sm mb-4'>
                                choose a specific amount from your token balance to start selling it.
                            </p>
                        </div>
                        <div className='col-lg-6 d-none d-lg-block text-end'>
                            <p className='mb-0'>
                                Remaining <span className='text-primary fw-bold mx-2'>{tokenCtx.tokenData.symbol}</span>{' '}
                                Balance
                            </p>
                            {userCtx.userInformation && <h4 className='text-primary h2'>{totalAvailable}</h4>}
                        </div>
                    </div>

                    {parseFloat(tokenCtx.totalSupply) > 0 ? (
                        <form onSubmit={removeVestingTokens}>
                            <button className='btn btn-primary w-100' type='submit'>
                                Withdraw your Tokens
                            </button>
                        </form>
                    ) : (
                        <>
                            <button className='btn btn-primary w-100 mb-2' type='button' disabled={true}>
                                Withdraw your Tokens
                            </button>
                            <p className='text-muted mb-0 text-sm fst-italic'>
                                There's no app token balance, please add tokens first
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default WithdrawTokens;
