import React, { useEffect, useContext } from 'react';
import { appSettings } from '../helpers/settings';
import AOS from 'aos';
import TokenContext from '../store/token-context';
import UserContext from '../store/user-context';
import PendingRequests from './admin/PendingRequests';
import WhiteList from './admin/WhiteList';
import FetchingDataLoader from './general/FetchingDataLoader';
import WhiteListedUsers from './admin/WhitlistedUsers';
import CancelPeriod from './admin/CancelPeriod';
import VestingTokens from './admin/VestingTokens';
import WithdrawTokens from './admin/WithdrawTokens';

function Admin() {
    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${appSettings.brandName} | Admin Panel`;
    }, []);

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    const tokenCtx = useContext(TokenContext);
    const userCtx = useContext(UserContext);

    if (tokenCtx.tokenIsLoading) {
        return <FetchingDataLoader />;
    }

    return (
        userCtx.appOwner && (
            <>
                <div className='row my-5 pt-4'>
                    <div className='col-lg-9 mx-auto text-center'>
                        <h1 data-aos='fade-up'>Admin Panel</h1>
                        <p className='text-muted lead' data-aos='fade-up' data-aos-delay='100'>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
                        </p>
                    </div>
                </div>

                <div className='row gy-4'>
                    <div className='col-lg-12'>
                        <VestingTokens />
                    </div>
                    <div className='col-lg-12'>
                        <WithdrawTokens />
                    </div>
                    <div className='col-lg-12'>
                        <PendingRequests />
                    </div>
                    <div className='col-lg-6'>
                        <WhiteList />
                    </div>
                    <div className='col-lg-6'>
                        <CancelPeriod />
                    </div>
                    <div className='col-lg-12'>
                        <WhiteListedUsers />
                    </div>
                </div>
            </>
        )
    );
}

export default Admin;
