import React, { useState, useContext, useEffect } from 'react';
import Web3Context from '../../store/web3-context';
import UserContext from '../../store/user-context';
import TokenContext from '../../store/token-context';
import web3 from '../../connection/web3';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import AOS from 'aos';
import MetaMaskLoader from './MetaMaskLoader';
import { useToasts } from 'react-toast-notifications';

function InfoForm({ fullName, email, role, about, setFullName, setEmail, setRole, setAbout }) {
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const tokenCtx = useContext(TokenContext);
    const [metaMaskOpened, setMetaMaskOpened] = useState(false);
    const { addToast } = useToasts();

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    /*** =============================================== */
    //      REACT HOOK FORM VALIDATION
    /*** =============================================== */
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(data) {
        if (
            userCtx.usersList &&
            userCtx.usersList
                .filter((user) => user.account !== web3Ctx.account)
                .map((el) => el.fullName.trim())
                .includes(data.fullName.trim())
        ) {
            addToast('This name is already taken', {
                appearance: 'error',
            });
        } else if (
            userCtx.usersList &&
            data.email.trim() !== '' &&
            userCtx.usersList
                .filter((user) => user.account !== web3Ctx.account)
                .map((el) => el.email)
                .includes(data.email)
        ) {
            addToast('This email is already taken', {
                appearance: 'error',
            });
        } else {
            tokenCtx.contract.methods
                .addUser(web3Ctx.account, data.fullName, data.email, data.role, data.about)
                .send({ from: web3Ctx.account })
                .once('sending', function (payload) {
                    setMetaMaskOpened(true);
                })
                .on('transactionHash', (hash) => {
                    setMetaMaskOpened(true);
                })
                .on('receipt', (receipt) => {
                    userCtx.getUsersList(userCtx.contract, tokenCtx.customTokenContract);
                    userCtx.getUserInformation(userCtx.contract, tokenCtx.customTokenContract, web3Ctx.account);
                    web3Ctx.loadAccount(web3);
                    addToast('Cool! your data has been updated!', {
                        appearance: 'success',
                    });
                    setMetaMaskOpened(false);
                })
                .on('error', (e) => {
                    addToast('Something went wrong when pushing to the blockchain', {
                        appearance: 'error',
                    });
                    setMetaMaskOpened(false);
                });
        }
    }

    return (
        <>
            {metaMaskOpened ? <MetaMaskLoader /> : null}
            <div className='card' data-aos='fade-up' data-aos-delay='300'>
                <div className='card-body p-lg-5'>
                    {userCtx.userInformation && userCtx.userInformation.fullName !== '' ? (
                        <h4 className='text-center fw-bold text-uppercase letter-spacing-0'>Update your Infromation</h4>
                    ) : (
                        <h4 className='text-center fw-bold text-uppercase letter-spacing-0'>Create an Account</h4>
                    )}
                    <p className='text-muted mb-5 text-center'>
                        Please enter your details below and click register.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row gy-4'>
                            <div className='col-lg-6'>
                                <label className='form-label'>Full Name</label>
                                <input
                                    type='text'
                                    className={`${errors.fullName ? 'is-invalid' : null} form-control`}
                                    placeholder='e.g. Jason Doe'
                                    defaultValue={fullName ? fullName : ''}
                                    onChange={(e) => setFullName(e.target.value)}
                                    {...register('fullName', { required: true, minLength: 6, maxLength: 20 })}
                                />
                                {errors.fullName && (
                                    <span className='invalid-feedback'>Please enter your full name</span>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <label className='form-label'>Email Address</label>
                                <input
                                    type='email'
                                    className='form-control'
                                    placeholder='e.g. jasondoe@gmail.com'
                                    defaultValue={email ? email : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    {...register('email')}
                                />
                            </div>
                            <div className='col-lg-12'>
                                {/* <label className='form-label'>Role</label> */}
                                <label className='form-label'>Referral</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    // placeholder='i.e. software engineer'
                                    defaultValue={role ? role : ''}
                                    onChange={(e) => setRole(e.target.value)}
                                    {...register('role')}
                                />
                            </div>
                            <div className='col-lg-12'>
                                <label className='form-label'>About</label>
                                <textarea
                                    className='form-control'
                                    rows='7'
                                    defaultValue={about ? about : ''}
                                    onChange={(e) => setAbout(e.target.value)}
                                    placeholder='Enter some brief about yourself'
                                    {...register('about')}
                                ></textarea>
                            </div>
                            {web3Ctx.account ? (
                                <div className='col-12'>
                                    <button className='btn btn-primary w-100' type='submit'>
                                        {userCtx.userInformation && userCtx.userInformation.fullName !== ''
                                            ? 'Update your info'
                                            : 'Create an Account'}
                                    </button>
                                </div>
                            ) : (
                                <a
                                    href='https://metamask.io/download/'
                                    className='btn btn-primary text-nowrap w-100'
                                    rel='noreferrer'
                                    target='_blank noopener'
                                >
                                    Install MetaMask
                                </a>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default InfoForm;
