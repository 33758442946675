import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { appSettings } from '../../helpers/settings';
import { Jazzicon } from '@ukstv/jazzicon-react';
import Web3Context from '../../store/web3-context';
import UserContext from '../../store/user-context';
import Web3 from 'web3';

function Navbar() {
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const [networkId, setNetworkId] = useState(4);

    /*** =============================================== */
    //      CONNECT WALLET
    /*** =============================================== */
    const connectWalletHandler = async () => {
        try {
            // Request account access
            await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch (error) {
            console.error(error);
        }
        // Load accounts
        web3Ctx.loadAccount(new Web3(window.ethereum));
    };

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(new Web3(window.ethereum));
                setNetworkId(networkId);
            }
        }
        getNetworkId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header className='main-header mb-5'>
            <div className='container pt-3'>
                <nav className='navbar w-100 navbar-expand-lg py-1 px-4 justify-content-start align-items-center'>
                    <Link className='navbar-brand' to='/'>
                        <img
                            src={appSettings.logo}
                            alt={appSettings.brandName}
                            width={appSettings.logoWidth}
                            className='h-auto'
                        />
                    </Link>
                    <h1 className='mone-text' style={{fontFamily: 'Agbalumo', fontWeight: 400}}>MONE</h1>

                    {/* <button
                        className='navbar-toggler shadow-0 p-0 border-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon'></span>
                    </button> */}
                </nav>
            </div>
        </header>
    );
}

export default Navbar;