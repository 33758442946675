import React from 'react';

const TokenContext = React.createContext({
    contract: null,
    customTokenContract: null,
    totalSupply: null,
    activePeriod: null,
    periods: null,
    appOwner: null,
    activity: null,
    tokenPrice: null,
    tokenData: { name: '', symbol: '' },
    minimumTokens: null,
    pendingTokens: null,
    purchasedTokens: null,
    tokenIsLoading: true,
    loadContract: () => {},
    loadCustomTokenContract: () => {},
    loadTotalSupply: () => {},
    loadAppOwner: () => {},
    loadActivePeriod: () => {},
    loadPeriods: () => {},
    loadTokenPrice: () => {},
    loadTokenData: () => {},
    loadMinimumTokens: () => {},
    loadPendingTokens: () => {},
    loadPurchasedTokens: () => {},
    setTokenIsLoading: () => {},
});

export default TokenContext;
