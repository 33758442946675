import React, { useEffect, useContext } from 'react';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { Image } from '@mantine/core';

function Banner () {
    const autoplay = useRef(Autoplay({ delay: 5000 }));
  return (
    <Carousel
        // maw={320}
        mx="auto"
        withIndicators
        // height={200}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        >
        <Carousel.Slide>
            <Image
                className='rounded-3'
                src='/banner/banner-5.png' alt='main-banner'
            />
        </Carousel.Slide>
        <Carousel.Slide>
            <Image
                className='rounded-3'
                src='/banner/banner-4.png' alt='main-banner'
            />
        </Carousel.Slide>
        <Carousel.Slide>
        <Image
                className='rounded-3'
                src='/banner/banner-3.png' alt='main-banner'
            />
        </Carousel.Slide>
        <Carousel.Slide>
        <Image
                className='rounded-3'
                src='/banner/banner-1.png' alt='main-banner'
            />
        </Carousel.Slide>
    </Carousel>
  )
}

export default Banner