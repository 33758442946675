export const appSettings = {
    // logo: '/mone-logo.svg',
    logo: 'logos/Mone-Coin-55.png',
    logoWidth: '55',
    tokenName: 'Mone Token',
    tokenAddress: '0x6d5Be51BF550b6e1B793Fa99503926e04f83F76a',
    activeNetworkName: 'Ethereum Mainnet Network',
    brandName: 'Mone Dollar Token',
    brandDescription: 'Revolutionizing the Memeconomy with Humor and Currency',
    marketplaceAuthor: 'Mone',
    // rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    // networkId: 97,
    // blockExplorerUrls: 'https://testnet.bscscan.com/',
    rpcUrl: 'https://mainnet.infura.io/v3/',
    networkId: 1,
    blockExplorerUrls: 'https://etherscan.io',
    currency: 'ETH',
    copyrightsLink: 'https://youtube.com/@mone',
    copyrightsBrand: 'Mone',
};
