import React, { useReducer } from 'react';

import TokenContext from './token-context';

const defaultTokenState = {
    contract: null,
    customTokenContract: null,
    appOwner: null,
    totalSupply: null,
    activity: null,
    activePeriod: null,
    periods: null,
    tokenPrice: null,
    tokenData: { name: '', symbol: '' },
    minimumTokens: null,
    purchasedTokens: null,
    pendingTokens: null,
    tokenIsLoading: true,
};

const TokenReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'CUSTOMTOKENCONTRACT') {
        return {
            ...state,
            customTokenContract: action.customTokenContract,
        };
    }

    if (action.type === 'LOADTOTALSUPPLY') {
        return {
            ...state,
            totalSupply: action.totalSupply,
        };
    }

    if (action.type === 'GETTOKENDATA') {
        return {
            ...state,
            tokenData: {
                name: action.tokenData.tokenName,
                symbol: action.tokenData.tokenSymbol,
            },
        };
    }

    if (action.type === 'GETACTIVEPERIOD') {
        return {
            ...state,
            activePeriod: {
                ...state.activePeriod,
                startTime: action.activePeriod[0],
                endTime: action.activePeriod[1],
                tokenPrice: action.activePeriod[2],
                tokenAmount: action.activePeriod[3],
                remainingTokens: action.activePeriod[4],
                status: action.activePeriod[5],
            },
        };
    }

    if (action.type === 'GETPERIODS') {
        return {
            ...state,
            periods: action.periods.map((period) => {
                return {
                    startTime: period[0],
                    endTime: period[1],
                    tokenPrice: period[2],
                    tokenAmount: period[3],
                    remainingTokens: period[4],
                    status: period[5],
                };
            }),
        };
    }

    if (action.type === 'GETOWNER') {
        return {
            ...state,
            appOwner: action.appOwner,
        };
    }

    if (action.type === 'GETACTIVITY') {
        return {
            ...state,
            activity: action.activity,
        };
    }

    if (action.type === 'GETTOKENPRICE') {
        return {
            ...state,
            tokenPrice: action.tokenPrice,
        };
    }

    if (action.type === 'GETMINIMUMTOKENS') {
        return {
            ...state,
            minimumTokens: parseFloat(action.minimumTokens),
        };
    }

    if (action.type === 'GETPURCHASEDTOKENS') {
        return {
            ...state,
            purchasedTokens: action.purchasedTokens.map((token) => {
                return {
                    account: token[0],
                    amount: token[1],
                };
            }),
        };
    }

    if (action.type === 'GETPENDINGTOKENS') {
        return {
            ...state,
            pendingTokens: action.pendingTokens.map((token) => {
                return {
                    account: token[0],
                    amount: token[1],
                };
            }),
        };
    }

    if (action.type === 'LOADING') {
        return {
            ...state,
            tokenIsLoading: action.loading,
        };
    }

    return defaultTokenState;
};

const TokenProvider = (props) => {
    const [TokenState, dispatchTokenAction] = useReducer(TokenReducer, defaultTokenState);

    const loadContractHandler = (web3, CryptoToken, deployedNetwork) => {
        const contract = deployedNetwork ? new web3.eth.Contract(CryptoToken.abi, deployedNetwork.address) : '';
        dispatchTokenAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const loadCustomTokenContractHandler = (web3, CryptoToken, tokenAddress, account) => {
        const customTokenContract = new web3.eth.Contract(CryptoToken, tokenAddress, {
            from: account,
        });
        dispatchTokenAction({ type: 'CUSTOMTOKENCONTRACT', customTokenContract: customTokenContract });
        return customTokenContract;
    };

    const loadActivePeriodHandler = async (contract) => {
        try {
            const activePeriod = await contract.methods.getActivePeriod().call();
            dispatchTokenAction({ type: 'GETACTIVEPERIOD', activePeriod: activePeriod });
            return activePeriod;
        } catch (err) {
            console.log('loadActivePeriodHandler');
        }
    };

    const loadPeriodsHandler = async (contract) => {
        try {
            const periods = await contract.methods.getPeriods().call();
            dispatchTokenAction({ type: 'GETPERIODS', periods: periods });
            return periods;
        } catch (error) {
            console.log('loadPeriodsHandler');
        }
    };

    const loadTotalSupplyHandler = async (contract) => {
        try {
            const totalSupply = await contract.methods.totalSupply().call();
            dispatchTokenAction({ type: 'LOADTOTALSUPPLY', totalSupply: totalSupply });
            return totalSupply;
        } catch (error) {
            console.log('loadTotalSupplyHandler');
        }
    };

    const loadAppOwnerHandler = async (contract) => {
        try {
            const appOwner = await contract.methods.owner().call();
            dispatchTokenAction({ type: 'GETOWNER', appOwner: appOwner });
            return appOwner;
        } catch (error) {
            console.log('loadAppOwnerHandler');
        }
    };

    const loadActivityHandler = async (contract) => {
        try {
            const activity = await contract.methods.getActivities().call();
            dispatchTokenAction({ type: 'GETACTIVITY', activity: activity });
            return activity;
        } catch (error) {
            console.log('loadActivityHandler');
        }
    };

    const loadPurchasedTokensHandler = async (contract, account) => {
        try {
            const purchasedTokens = await contract.methods.getPurchasedTokens(account).call();
            dispatchTokenAction({ type: 'GETPURCHASEDTOKENS', purchasedTokens: purchasedTokens });
            return purchasedTokens;
        } catch (error) {
            console.log('loadPurchasedTokensHandler');
        }
    };

    const loadTokenPriceHandler = async (contract) => {
        try {
            const tokenPrice = await contract.methods.price().call();
            dispatchTokenAction({ type: 'GETTOKENPRICE', tokenPrice: tokenPrice });
            return tokenPrice;
        } catch (error) {
            console.log('loadTokenPriceHandler');
        }
    };

    const loadMinmumTokensHandler = async (contract) => {
        try {
            const minimumTokens = await contract.methods.getMinimumTokens().call();
            dispatchTokenAction({ type: 'GETMINIMUMTOKENS', minimumTokens: minimumTokens });
            return minimumTokens;
        } catch (error) {
            console.log('loadMinmumTokensHandler');
        }
    };

    const loadPendingTokensHandler = async (contract) => {
        try {
            const pendingTokens = await contract.methods.getPendingTokens().call();
            dispatchTokenAction({ type: 'GETPENDINGTOKENS', pendingTokens: pendingTokens });
            return pendingTokens;
        } catch (error) {
            console.log('loadPendingTokensHandler');
        }
    };

    const loadTokenDataHandler = async (contract) => {
        try {
            const tokenName = await contract.methods.name().call();
            const tokenSymbol = await contract.methods.symbol().call();
            dispatchTokenAction({ type: 'GETTOKENDATA', tokenData: { tokenName, tokenSymbol } });
        } catch (error) {
            console.log(error);
        }
    };

    const setTokenIsLoadingHandler = (loading) => {
        dispatchTokenAction({ type: 'LOADING', loading: loading });
    };

    const tokenContext = {
        contract: TokenState.contract,
        customTokenContract: TokenState.customTokenContract,
        totalSupply: TokenState.totalSupply,
        tokenIsLoading: TokenState.tokenIsLoading,
        activePeriod: TokenState.activePeriod,
        periods: TokenState.periods,
        appOwner: TokenState.appOwner,
        activity: TokenState.activity,
        tokenPrice: TokenState.tokenPrice,
        tokenData: TokenState.tokenData,
        minimumTokens: TokenState.minimumTokens,
        purchasedTokens: TokenState.purchasedTokens,
        pendingTokens: TokenState.pendingTokens,
        loadContract: loadContractHandler,
        loadCustomTokenContract: loadCustomTokenContractHandler,
        loadTotalSupply: loadTotalSupplyHandler,
        loadAppOwner: loadAppOwnerHandler,
        loadActivity: loadActivityHandler,
        loadTokenPrice: loadTokenPriceHandler,
        loadMinimumTokens: loadMinmumTokensHandler,
        loadActivePeriod: loadActivePeriodHandler,
        loadPeriods: loadPeriodsHandler,
        loadPurchasedTokens: loadPurchasedTokensHandler,
        loadPendingTokens: loadPendingTokensHandler,
        loadTokenData: loadTokenDataHandler,
        setTokenIsLoading: setTokenIsLoadingHandler,
    };

    return <TokenContext.Provider value={tokenContext}>{props.children}</TokenContext.Provider>;
};

export default TokenProvider;
