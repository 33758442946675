import { Flex, CopyButton, Button } from '@mantine/core';
import AOS from 'aos';
import React, { useEffect } from 'react';
import { appSettings } from '../helpers/settings';
import { Image } from '@mantine/core';
import Banner from './home/Banner';
import styles from './styles/Home.module.css';

// import FetchingDataLoader from './general/FetchingDataLoader';

function Home() {
    // const userCtx = useContext(UserContext);
    // const tokenCtx = useContext(TokenContext);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${appSettings.brandName}`;
    }, []);

    /*** =============================================== */
    //      AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    // if (userCtx.userIsLoading) {
    //     return <FetchingDataLoader />;
    // }

    return (
        <>
            <div className='row'>
                <div className='col-lg-9 col-md-12 mb-4 mx-auto'>
                <Image
                    className='rounded-3'
                    src='/banner/banner-5.png' alt='main-banner'
                />
                    {/* <Banner /> */}
                </div>
            </div>
            {/* <div className="row my-5 gy-5 align-items-center">
                <div className="col-lg-7 text-center text-lg-start">
                    <h1 data-aos="fade-up" className="text-xl">
                        Mone - Revolutionizing the Memeconomy with Humor and
                        Currency
                    </h1>
                    <p
                        className="text-muted lead"
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >
                        Welcome to the world of Meme Dollar Token (MONE), where
                        memes meet money, and laughter is the ultimate currency.
                    </p>
                </div>
                <div className="col-lg-5">
                    <img
                        src="/mone-rocket.png"
                        alt="Mone rocket"
                        className="img-fluid w-100 mb-5"
                        data-aos="fade-up"
                    />
                </div>
            </div> */}
            <div className='text-center fira-font mb-4'>
                ERC20 Smart Contract Address<br/>
                <div style={{
                    fontFamily: 'Orbitron',
                    // fontFamily: 'Fira Code',
                    width: 300,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    letterSpacing: '.05em'
                }} className='fira-font mb-4'>0x24a5FE2C2240e58252CDFc43bB94ae67d2eAD218</div>
                <CopyButton value="0x24a5FE2C2240e58252CDFc43bB94ae67d2eAD218">
                {({ copied, copy }) => (
                    <Button className="mb-3 mx-auto" color={copied ? 'teal' : 'blue'} onClick={copy}>
                    <i class="fa-regular fa-copy me-2"></i> {copied ? 'Copied to clipboard' : 'Copy to clipboard'}
                    </Button>
                )}
                </CopyButton>
            </div>

            <div className="row mb-2">
                <div className="col-9-md mx-auto">
                    <a href="https://www.orangex.com/spot/MONE-USDT-SPOT" class="btn bg-orange btn-lg w-100" style={{ background: '#FF8200' }}>Buy Coin on OrangeX</a>
                </div>
            </div>
            {/* <div className="row mb-2"> */}
                {/* <div className="col-9-md mx-auto"> */}
                    {/* <a href="https://app.uniswap.org/swap?outputCurrency=0x24a5fe2c2240e58252cdfc43bb94ae67d2ead218" class="btn bg-orange btn-lg w-100">Buy Coin on Uniswap</a> */}
                    {/* <a href="http://mone.yt/buytoken" class="btn bg-orange btn-lg w-100">Buy Token on Uniswap</a> */}
                {/* </div> */}
            {/* </div> */}

            <div className="row mb-5">
                <div className="col-9-md mx-auto">
                    <a href="https://mone.yt/whitepapper/mone-whitepaper.pdf" class="btn btn-lg w-100"  style={{ backgroundColor: '#54b957' }}>Read Whitepaper</a>
                </div>
            </div>

            <section className="py-5 about-section" >
                <div className="row align-items-center">
                    <div className="col-lg-5 img-wrapper">
                        <img
                            src="/Dollar-king.png"
                            alt="Mone rocket"
                            className="img-fluid w-100 mb-5"
                            data-aos="fade-up"
                        />
                    </div>
                    <div className="col-lg-7 text-center text-lg-start">
                        <h1 data-aos="fade-up" className="text-xl about-mone">
                            About Mone
                        </h1>
                        <p
                            className="text-muted lead pt-2"
                            data-aos="fade-up"
                            data-aos-delay="100"
                        >
                            Welcome to the world of Mone, where fun meets finance. Mone was created not only for humor, but has a vision to be the world's meme coin king.
                        </p>
                        <p
                            className="text-muted lead pt-2"
                            data-aos="fade-up"
                            data-aos-delay="100"
                        >
                            Shiba, floki, doge, pepe and many other meme coins are just born trending at a glance, Mone is here to become a legend in the Crypto world and make the Mone community successful and happy together.
                        </p>
                    </div>
                    <div className='col-lg-9 col-md-12 mb-4 mx-auto'>
                    <Image
                        className='rounded-3'
                        src='/banner/banner-3.png' alt='main-banner'
                    />
                        {/* <Banner /> */}
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="row">
                    <div className="col-lg-12">
                        <h1
                            data-aos="fade-up"
                            className="text-xl text-center about-mone"
                            style={{ marginBottom: '80px' }}
                        >
                            How to Buy
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body p-lg-5">
                                    <h3 data-aos="fade-up mb-5">
                                        🚀 :
                                    </h3>
                                    <p>
                                    Download the OrangeX Exchange application on Playstore or Appstore
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body p-lg-5">
                                    <h3 data-aos="fade-up mb-5">
                                        🚀🚀 :
                                    </h3>
                                    <p>
                                    Deposit funds on OrangeX exchange or transfer coins from your wallet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body p-lg-5">
                                    <h3 data-aos="fade-up mb-5">
                                        🚀🚀🚀 :
                                    </h3>
                                    <p>
                                    Buy Mone coin on OrangeX exchange, Swap using USDT
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body p-lg-5">
                                    <h3 data-aos="fade-up mb-5">
                                        🚀🚀🚀🚀 :
                                    </h3>
                                    <p>
                                    You can buy and sell mone coins on the OrangeX exchange
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                {/* <div className="row">
                    <div className="col-lg-12">
                        <h1 data-aos='fade-up' className='text-xl text-center about-mone' style={{marginBottom: '80px'}}>
                            How to Buy Mone Token
                        </h1>
                    </div>
                </div> */}
                <div className="row align-items-center">
                    <div className="col-lg-5 text-center text-lg-start">
                        <h1 data-aos="fade-up" className="text-xl about-mone" style={{marginBottom: '40px'}}>
                            TOKENOMICS
                            <br />
                        </h1>
                        <h2 style={{ letterSpacing: '2px' }}>
                            Supply:
                            <span className="color-hilite"> 2 000 000 000</span>
                            <br />
                            Symbol:<span className="color-hilite"> MONE</span>
                            <br />
                            Decimal:<span className="color-hilite"> 18</span>
                            <br />
                        </h2>
                        <p
                            className="text-muted lead pt-2"
                            data-aos="fade-up"
                            data-aos-delay="100"
                        >
                            Mone Token operates on a highly efficient and secure
                            Ethereum blockchain infrastructure. Here are the key
                            tokenomics of MONE MemeCoin Dollar
                        </p>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <img
                            src="background/texonomics.svg"
                            alt="Dollar Pile"
                            className="img-fluid w-100 mb-5"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
            </section>

            <div class="container">
                <div class="row">
                    <div className="col-12">
                        <h1 data-aos="fade-up" className="text-xl mb-7 text-center about-mone">
                            ROADMAP
                            <br />
                        </h1>
                    </div>
                    <div class="col-md-12">
                        <div class="Yearly-timeline">
                            <div class="timeline">
                                <a href="" class="timeline-content">
                                    {/* <div class="timeline-icon"><i class="fa fa-instagram"></i></div> */}
                                    <h2 className="title">Stage 1</h2>
                                    <ul className="description body-muted">
                                        <li>
                                            Launch mone (Ethereum Blockchain)
                                        </li>
                                        <li>Mone meme creation</li>
                                        <li>Mone Presale</li>
                                        <li>Get Mone Trending</li>
                                    </ul>
                                    <img className='bg-icon' src="/background/mone-coin.svg" alt="" />
                                    {/* <p class="description body-muted">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo
                            </p> */}
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="" class="timeline-content">
                                    {/* <div class="timeline-icon"><i class="fa fa-twitter"></i></div>
                            <h3 class="title">2013-2017</h3>
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo
                            </p> */}
                                    <h2 className="title">Stage 2</h2>
                                    <ul className="description body-muted">
                                        <li>Community Partnership</li>
                                        <li>CMC Listing</li>
                                        <li>More newsletter</li>
                                        <li>Cex Listing</li>
                                    </ul>
                                    <img className='bg-icon' src="/background/candlestick.svg" alt="" />
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="" class="timeline-content">
                                    {/* <div class="timeline-icon"><i class="fa fa-facebook"></i></div>
                            <h3 class="title">2009-2012</h3>
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo
                            </p> */}
                                    <h2 className="title">Stage 3</h2>
                                    <ul className="description body-muted">
                                        <li>T1 Exchange</li>
                                        <li>Mone merchandise</li>
                                        <li>Mone Academy</li>
                                        <li>NFT Launch</li>
                                    </ul>
                                    <img className='bg-icon' src="/background/mone-store.svg" alt="" />
                                </a>
                            </div>
                            <div class="timeline">
                                <a href="" class="timeline-content">
                                    {/* <div class="timeline-icon"><i class="fa fa-linkedin"></i></div>
                            <h3 class="title">2006-2009</h3>
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo
                            </p> */}
                                    <h2 className="title">Stage 4</h2>
                                    <ul className="description body-muted">
                                        <li>Mone to the moon</li>
                                        <li>Rich together</li>
                                    </ul>
                                    <img className='bg-icon' src="/background/rocket-312767.svg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-5 mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <h1
                            data-aos="fade-up"
                            className="text-xl text-center about-mone"
                            style={{ marginBottom: '80px' }}
                        >
                            OUR PARTNER
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-4">
                        <div className="card d-flex align-items-center justify-content-center" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/full-bitget-logo.svg"  width="80%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/trust-wallet.svg"  height="100%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/onekey_logo.svg"  height="100%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/MetaMask.svg"  height="100%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card d-flex align-items-center justify-content-center" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/etherscan-logo.svg"  width="80%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/Ethereum.svg"  height="100%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '10px', height: '160px'}}>
                            <img src="/logos/1inch_color_black.svg"  height="100%" alt="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card" style={{padding: '40px', height: '160px'}}>
                            <img src="/logos/logo-viawallet-small.png" height='100%' alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="row">
            <img src="/banner/pist.png" className='mx-auto my-5 img-fluid' style={{maxWidth: '400px'}} alt="" />
            </div>


            <div className='row mb-5'>
                <div className="col-12 mx-auto">
                <section className={styles.contactSection} id="contact">
                    <div className={styles.contactSocialSide} style={{ width: '100%' }}>
                    <h1
                            data-aos="fade-up"
                            className="text-xl text-center about-mone"
                            style={{ marginBottom: '80px' }}
                        >
                            Contact Us
                        </h1>
                    <div className={styles.contactSocialBlock}>
                        <Flex gap={'md'} justify="center" align={'center'}>
                            {/* <a href="mailto:support@bymci.com" target="_blank" rel="noreferrer"><i className="las la-envelope"></i></a> */}
                            <a href="https://instagram.com/monememecoin" target="_blank" rel="noreferrer"><i className="lab la-instagram"></i></a>
                            {/* <a href="https://twitter.com/bymciofficial" target="_blank" rel="noreferrer"><i className="lab la-twitter-square"></i></a> */}
                            <a href="https://www.tiktok.com/@mone.coin" target="_blank" rel="noreferrer"><i class="fa-brands fa-tiktok fs-4"></i></a>
                            {/* <a href="https://discord.gg/N7Xkqv2kSs" target="_blank" rel="noreferrer"><i className="lab la-discord"></i></a> */}
                            <a href="https://discord.com/invite/tRV4CANyJc" target="_blank" rel="noreferrer"><i className="lab la-discord"></i></a>
                            {/* <a href="https://twitter.com/Mr_Mone" target="_blank" rel="noreferrer"><i class="lab la-twitter"></i></a> */}
                            <a href="https://x.com/monec0in" target="_blank" rel="noreferrer"><i class="lab la-twitter"></i></a>
                            <a href="https://t.me/monememe" target="_blank" rel="noreferrer"><i class="lab la-telegram"></i> <span style={{ color: 'rgb(141 147 73)' }}>Channel</span></a>
                        </Flex>
                        <Flex gap={'md'} justify="center" align={'center'}>
                            {/* <a href="https://t.me/monememecoin" target="_blank" rel="noreferrer"><i class="lab la-telegram"></i> <span style={{ color: 'rgb(141 147 73)' }}>Group</span></a> */}
                            <a href="https://t.me/monec0in" target="_blank" rel="noreferrer"><i class="lab la-telegram"></i> <span style={{ color: 'rgb(141 147 73)' }}>Group</span></a>
                        </Flex>
                    </div>
                    <div className='text-center'><a href="mailto:support@mone.yt" style={{ color: 'white' }}>support@mone.yt</a></div>
                    </div>
                </section>
                </div>
            </div>

            <h6 className='text-center body-muted' style={{marginTop: '40px'}}>Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.</h6>
        </>
    );
}

export default Home;
