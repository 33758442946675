import web3 from '../connection/web3';

export const DECIMALS = 10 ** 15;

export const ether = (wei) => wei * DECIMALS;

export const formatPrice = (price) => {
    price = Math.round(ether(price));

    return price;
};

export function formatDate(date) {
    return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()} ${new Date(
        date
    ).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    })}`;
}

export function toEther(number) {
    return web3.utils.fromWei(new web3.utils.BN(number.toString()), 'ether');
}

export function precision(a) {
    var e = 1;
    while (Math.round(a * e) / e !== a) e *= 10;
    return Math.log(e) / Math.LN10;
}
